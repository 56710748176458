import React, { useState, useContext } from 'react'
import styled, { css } from 'styled-components'
import Button from '@material-ui/core/Button'
import OutlinedInput from '@material-ui/core/OutlinedInput'
import CircularProgress from '@material-ui/core/CircularProgress'

import { AUTH0_CUSTOM_CLAIMS_NAMESPACE } from 'src/utils/constants'
import { IdTokenContext } from 'src/utils/IdTokenContext'
import { useSetAppMetadataMutation } from 'src/generated/hooks'
import { isErrorResult } from 'src/utils/typeGuards'

const Container = styled.div(
  ({ theme }) => css`
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    width: 100%;
    margin-top: ${theme.spacing(3)}px;
  `
)

const InputLabel = styled.label(
  ({ theme }) => css`
    display: block;
    padding-bottom: ${theme.spacing(0.5)}px;
    font-weight: ${theme.typography.fontWeightMedium};
    font-size: ${theme.typography.pxToRem(13)};
    margin-top: ${theme.spacing(2)}px;
  `
)

const GoToConnectButton = styled(Button)(
  ({ theme }) => css`
    padding: ${theme.spacing(0)}px;
    height: 48px;
  `
)

const ErrorContainer = styled.div`
  height: 75px;
`

const ErrorMessage = styled.div(
  ({ theme }) => css`
    color: ${theme.palette.error.main};
    margin: ${theme.spacing(1, 0, 3)};
    background-color: ${theme.palette.error.xlight};
    padding: ${theme.spacing(1, 1.5)};
    border-radius: 4px;
  `
)

const DEFAULT_ERROR_MESSAGE = 'An error occurred'

const AdminUi = () => {
  const idToken = useContext(IdTokenContext)
  const market =
    idToken?.[
      `${AUTH0_CUSTOM_CLAIMS_NAMESPACE}/digitalhub.admin_markets`
    ]?.toString() ?? ''

  const [userEmail, setUserEmail] = useState('')
  const [error, setError] = useState('')

  const [setAppMetadata, { loading: setAppMetadataLoading }] =
    useSetAppMetadataMutation()

  const onSubmit = async () => {
    setError('')
    const response = await setAppMetadata({
      variables: {
        input: {
          email: userEmail,
        },
      },
    })
    const result = response.data?.setAppMetadata

    if (isErrorResult(result)) {
      setError(result.message ?? DEFAULT_ERROR_MESSAGE)
    } else if (result?.isSuccess) {
      window.location.href = import.meta.env.REACT_APP_CONNECT_APP_URL!
    } else {
      setError(DEFAULT_ERROR_MESSAGE)
    }
  }

  const keyupHandler = (evt: React.KeyboardEvent) => {
    if (evt.key === 'Enter') {
      onSubmit()
    }
  }

  return (
    <Container>
      <InputLabel htmlFor="market">Authorised Market</InputLabel>
      <OutlinedInput id="market" disabled fullWidth value={market ?? '-'} />
      <InputLabel htmlFor="user-email">User Email</InputLabel>
      <OutlinedInput
        id="user-email"
        autoComplete="off"
        fullWidth
        placeholder="Enter user email"
        value={userEmail}
        onChange={e => setUserEmail(e.target.value)}
        onKeyUp={keyupHandler}
      />
      <ErrorContainer>
        {error && <ErrorMessage role="alert">{error}</ErrorMessage>}
      </ErrorContainer>
      <GoToConnectButton
        color="primary"
        variant="contained"
        disabled={setAppMetadataLoading}
        onClick={onSubmit}
        fullWidth
      >
        {setAppMetadataLoading ? (
          <CircularProgress size={20} />
        ) : (
          <span>Login to Connect</span>
        )}
      </GoToConnectButton>
    </Container>
  )
}

export default AdminUi
