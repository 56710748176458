import { useContext, PropsWithChildren } from 'react'
import {
  ApolloClient,
  InMemoryCache,
  createHttpLink,
  ApolloProvider as ApolloProviderBase,
} from '@apollo/client'
import { setContext } from '@apollo/client/link/context'

import { IdTokenContext } from 'src/utils/IdTokenContext'

const ApolloProvider = ({ children }: PropsWithChildren<{}>) => {
  const idToken = useContext(IdTokenContext)

  const httpLink = createHttpLink({
    uri: import.meta.env.REACT_APP_API_URL,
  })

  const authLink = setContext(() => ({
    headers: {
      Authorization: idToken?.__raw,
      'X-API-KEY': import.meta.env.REACT_APP_X_API_KEY,
    },
  }))

  const client = new ApolloClient({
    cache: new InMemoryCache(),
    uri: import.meta.env.REACT_APP_API_URL,
    link: authLink.concat(httpLink),
    defaultOptions: {
      mutate: {
        errorPolicy: 'all',
      },
      query: {
        errorPolicy: 'all',
      },
      watchQuery: {
        errorPolicy: 'all',
      },
    },
  })
  return <ApolloProviderBase client={client}>{children}</ApolloProviderBase>
}

export default ApolloProvider
